
import { Link } from "react-router-dom";


const Footer = () => {
    return(
   <>
        <footer className="site-footer"> 
          <div className="container mt-2">
            <div className="row">
              <div className="col-lg-7 col-12">
                <h3>
                  <div className="mt-4"><img src="assets/images/logo1re.png" alt="" className="w-25" style={{ backgroundColor: 'rgba(189, 186, 186, 0.692)' }}></img></div>
                </h3>
                <p className="text-white b mt-5">
                  <span style={{ backgroundColor: '#6c757d87' }}> <i className="bi bi-geo-alt-fill " />
                  Silver City, Sector 93, Noida, Uttar Pradesh 201304</span>
                </p>
              </div>
              <div className="col-lg-5 col-12 ">
                <div className="row">
                  <div className="col-12 mt-5">
                    <h5 className="text-white" style={{ backgroundColor: '#6c757d87', fontWeight: 600 }}>About Purvanchal Silver City 
                      <br /> Communication Update</h5>
                                  
                        <form className="form-subscribe mt-5 mb-5" >
                      <div className="input-group mt-5 ">
                        <input type="text" className="form-control input-lg mb-5" placeholder="Your eamil address" />
                        <span className="input-group-btn ">
                          <button className="btn btn-danger btn-lg mb-5" type="submit">Get Notified</button>
                        </span>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid p-3  " style={{ marginTop: '10px', backgroundColor: 'rgba(58, 56, 56, 0.5)', backfaceVisibility: '0.8' }}>
            <div className="row">
              <div className="col-lg-6 col-12 text-center">
                <Link to="#" style={{ color: 'rgb(247, 231, 2)', fontSize: '18px' }}> Purvanchal Silver City , All
                  rights reserved.</Link>
              </div>
              <div className="col-lg-6 col-12 text-center">
                <a href="https://www.ratnkunj.com/" style={{ color: 'rgb(247, 231, 2)', fontSize: '18px' }} target="_blank" rel="noopener noreferrer">Managed by
                  Ratnkunj.com </a>
              </div>
            </div>
          </div>
        </footer>
   </>

    );
}

export default Footer;